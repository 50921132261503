body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-close{
  background: none;
  width: 30px;
  height: 30px;
  border: 0;
}

.wrapper{
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.wrapper .box{
  background: #fff;
  width: calc(33% - 10px);
  padding: 25px;
  border-radius: 3px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
}
.wrapper .box i.quote{
  font-size: 20px;
  color: #fb3e3e ;
}
.wrapper .box .content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.box .info .name{
  font-weight: 600;
  font-size: 17px;
}
.box .info .job{
  font-size: 16px;
  font-weight: 500;
  color: #fb3e3e ;
}
.box .info .stars{
  margin-top: 2px;
}
.box .info .stars i{
  color: #fb3e3e ;
}
.box .content .image{
  height: 75px;
  width: 75px;
  padding: 3px;
  background: #fb3e3e ;
  border-radius: 50%;
}
.content .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}
.box:hover .content .image img{
  border-color: #fff;
}
@media (max-width: 1045px) {
  .wrapper .box{
    width: calc(50% - 10px);
    margin: 10px 0;
  }
}
@media (max-width: 702px) {
  .wrapper .box{
    width: 100%;
  }
}
.dv-star-rating-star{
  font-size: 30px;
}

.dv-star-rating-empty-star{
  color: rgb(51, 51, 51) !important;
} 

.dv-star-rating-full-star{
  color: #fb3e3e !important;
}

.jodit-status-bar__item:nth-child(4) { 
  display: none;
}




/* New Codes */
body{
  background-color: #171D33;
}
body,html{
  overflow-x: hidden;
}
#mainHome{
  min-height: 100vh;
  background-image: url('./images/mainBack.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  position: relative;
}
.mainTitle{
  font-size: 3em;
}
#mainHome .mainTitle{
  /* margin-top: 200px; */
}
.boxCon{
  display: flex;
  margin-top: 50px;
}
.countBox{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-left: #FF931E solid 8px;
  margin-right: 50px;
  min-width: 250px;
  z-index: 1;
}
.blueCount{
  color: #344EAA;
  font-size: 3em;
  font-weight: bold;
  margin-right: 20px;
}
.countBox .smallText{
  font-size: 0.9em;
  font-weight: 500;
}
.inquiryBtn{
  color: white !important;
  background: #D34134;
  box-shadow: 0px 0px 20px rgba(211, 65, 52, 0.6) !important;
  border-radius: 50px;
  text-align: center;
  margin-top: 80px;
  min-width: 200px;
  z-index: 1;
}
.mainGif{
  width: 600px;
  /* margin-top: 150px; */
}
.mainGifAbout{
  width: 800px;
  margin-top: auto;
}
.mainGifService{
  width: 700px;
  /* margin-top: 150px; */
}
#circle1{
  position: absolute;
  width: 276px;
  height: 276px;
  left: 600px;
  top: 311px;
  background: #FF931E;
  opacity: 0.5;
  filter: blur(150px);
}
#circle2{
  position: absolute;
  right: -50px;
  top: 200px;
  width: 276px;
  height: 276px;
  background: #D34134;
  mix-blend-mode: multiply;
  opacity: 0.5;
  filter: blur(150px);
}
#mainRow{
  min-height: 100vh;
}
.centerCols{
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.centerColBetween{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-title-small{
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 4px;
  color: #FF931E;
}
.section-title-main{
  color: white;
  font-size: 2.5em;
}
.workCon{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.workBox{
  width: 250px;
  background-color: white;
  border-radius: 15px;
  border-bottom: 6px solid #FF931E;
  padding: 20px;
  text-align: center;
  height: 270px;
}
.workBox img{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}
.workBox3 img{
  width: 200px;
}
.workBox p{
  color: #344EAA;
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
}
.playIcon{
  width: 40px;
  margin-left: 40px;
  margin-right: 40px;
}
.testiBox{
  background: #3A3F52;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px 50px 50px 15px;
  width: 90%;
  color: white;
  padding: 50px 80px 30px 50px;
  text-align: center;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left .testiBox{
  border-radius: 50px 15px 15px 50px;
}
.yellow.left .testiBox{
  border-left: none;
  border-right: 5px solid #FF931E;
}
.yellow .testiBox{
  border-left: 5px solid #FF931E;
}
.red.left .testiBox{
  border-left: none;
  border-right: 5px solid #D34134;
}
.red .testiBox{
  border-left: 5px solid #D34134;
}
.testiImg{
  border-radius: 10000px;
  width: 200px;
  height: 200px;
  margin-left: -20px;
  z-index: 1;
  object-fit: cover;
}
.left .testiImg{
  margin-right: -20px;
  margin-left: 0px;
}
.yellow .testiImg{
  border: 8px solid #FF931E;
}
.red .testiImg{
  border: 8px solid #D34134;
}

#contact{
  min-height: 100vh;
  background-image: url('./images/mainBack2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  position: relative;
}
#contact-form input[type="text"], #contact-form input[type="email"], #contact-form textarea{
  background: #D8D8D8;
  border-radius: 7px;
  color: #8B8B8B;
}
#contact-form input[type="submit"]{
  background: #D34134;
  box-shadow: 0px 0px 20px rgba(211, 65, 52, 0.6) !important;
  border-radius: 50px;
  color: white !important;
  font-weight: 500;
  margin-top: 40px;
}
#contact{
  padding-top: 100px;
}
.contactLogo{
  height: 110px;
  width: 100px;
  margin-left: auto;
  margin-top: -30px;
}
.mainPara{
  font-weight: bold;
  margin-top: 50px;
  max-width: 550px;
}
.smallBlueText{
  color: #344EAA;
  font-weight: bold;
  text-align: center;
}
.services-box {
  padding: 20px 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border-bottom: 8px solid #FF931E;
  min-height: 380px;
}
.serviceTitle{
  color:#344EAA;
  font-weight: bold;
}
.serviceImage{
  width: 100%;
}
.viewServiceBtn{
  background: #D34134;
  box-shadow: 0px 0px 20px rgba(211, 65, 52, 0.6) !important;
  border-radius: 50px;
  color: white;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;
}
.addReviewBtn{
  background: #D34134;
  box-shadow: 0px 0px 20px rgba(211, 65, 52, 0.6) !important;
  border-radius: 50px;
  color: white;
  margin-top: 20px;
}
.viewMoreBtn{
  background: #D34134;
  box-shadow: 0px 0px 20px rgba(211, 65, 52, 0.6) !important;
  border-radius: 50px;
  color: white;
  margin-top: 5px;
  font-size: 0.8em;
  padding: 8px 30px;
}
.modal-content{
  border-radius: 15px;
}
.viewServiceBtn:hover, .addReviewBtn:hover{
  color: white;
}
.reviewBox{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px !important;
  border-bottom: 8px solid #FF931E;
  min-height: 340px;
}
.eventBox{
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px !important;
  border-bottom: 8px solid #FF931E;
  padding: 20px;
  margin-bottom: 30px;
}
.eventBox img{
  border-radius: 10px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.eventBox h4{
  color: #344EAA;
  margin-top: 15px;
}
.eventBox p{
  margin-top: 10px;
  font-size: 0.8em;
  line-height: 18px;
}

@media (max-width:769px){
  .centerCols{
    min-height: auto;
    display: block;
  }
  .centerColsAbout{
    display: flex;
    justify-content: center;
    align-items:end;
  }
  .mainRow{
    padding-top: 200px;
    padding-bottom: 100px;
    text-align: center;
  }
  .mainRowAbout{
    padding-bottom: 0px;
  }
  .mainHomeAbout .container, .mainHomeAbout .container .row{
    min-height: 100vh;
  }
  .mainGifAbout{
    width: 110%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    /* margin-bottom: 0px; */
  }
  .countBox{
    margin: 0px;
  }
  .boxCon{
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
  }
  .mainPara {
      font-weight: bold;
      margin-bottom: 50px;
      max-width: 100%;
  }
  .mainGif{
    margin-top: 50px;
  }
  .mainGifService{
    width: 100%;
  }
  .playIcon{
    margin-left: 15px;
    margin-right: 15px;
  }
  .workBox3 img{
    width: 150px;
    margin-bottom: 25px;
  }
  .workBox p{
    font-size: 1.2em;
  }
  .tabRow{
    padding-left: 0px;
    padding-right: 0px;
  }
  .testiImg{
    width: 240px;
  }
  #contact{
    min-height: 100vh;
    background-image: url('./images/mainBack2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    position: relative;
  }
  .testiBox{
    height: auto;
    min-height: 200px;
  }
  .services-box{
    min-height: 300px;
  }
}

@media (max-width:576px){
  .boxCon{
    display: block;
  }
  .countBox{
    margin-bottom: 20px;
  }
  .mainRow{
    padding-top: 150px;
  }
  .mainTitle{
    font-size: 2em;
  }
  .inquiryBtn{
    margin-top: 40px;
  }
  .mainGif, .mainGifService{
    width: 100%;
  }
  .workCon{
    display: block;
    text-align: center;
  }
  .workBox{
    margin-left: auto;
    margin-right: auto;
  }
  .playIcon{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .centerColBetween{
    display: block;
  }
  #circle1{
    left: -200px;
    top: 200px;
  }
  #circle2{
    right: -50px;
    top: auto;
    bottom: 100px;
  }
  .hideMobile{
    display: none;
  }
  .testiImg{
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .mobileCenter{
    text-align: center;
  }
  .testiBox{
    border-radius: 15px 15px 15px 15px;
    width: 100%;
    height: 200px;
    padding: 40px 20px 30px 20px;
    display: block;
    height: auto;
    min-height: 200px;
    margin-top: 20px;
  }
  .left .testiBox{
    border-radius: 15px 15px 15px 15px;
  }
  .mobileSmallText{
    font-size: 1.5em;
  }
  .contactLogo{
    display: none;
  }
}


/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}
